'use client';

import { useEffect } from 'react';
import { captureException } from '@sentry/nextjs';
import { MentiError } from '@mentimeter/errors';
import { LocalStorage } from '@mentimeter/storage';
import { REGION_KEY, SESSION_TOKEN_KEY } from '@mentimeter/user/session';
import User, { USER_LOCALSTORAGE_KEY } from '@mentimeter/user';
import type { UserResponseT } from '@mentimeter/http-clients';
import { Box } from '@mentimeter/ragnar-ui';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { useSearchParams, useRouter } from '@mentimeter/next-navigation';
import { getSafeUrl, setSessionCookies } from '@mentimeter/auth';

export default function ConvertSessionPage() {
  const router = useRouter();
  const searchParams = useSearchParams();

  useEffect(() => {
    const continuePath = getSafeUrl(searchParams.get('continue'), '/app');
    const continueParam = continuePath ? `?continue=${continuePath}` : '';
    const loginContinueUrl = `/auth/login${continueParam}`;
    const sessionToken = LocalStorage.getItem(SESSION_TOKEN_KEY);
    const user = LocalStorage.getJSONItem<UserResponseT>(USER_LOCALSTORAGE_KEY);

    if (!sessionToken) {
      router.push(loginContinueUrl);
      return;
    }

    if (!user) {
      User.logout(loginContinueUrl);
      return;
    }

    LocalStorage.setItem({
      type: 'necessary',
      key: REGION_KEY,
      value: user.region,
    });
    convertToCookieBasedSession(
      sessionToken,
      user,
      router,
      continuePath,
      loginContinueUrl,
    );
  }, [router, searchParams]);

  return (
    <Box
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      bg="bg"
      p={3}
    >
      <LoaderIcon size={5} />
    </Box>
  );
}

async function convertToCookieBasedSession(
  sessionToken: string,
  user: UserResponseT,
  router: ReturnType<typeof useRouter>,
  continuePath: string,
  loginContinueUrl: string,
) {
  const response = await setSessionCookies(sessionToken, user.region);
  if (!response.ok) {
    await reportError(response);
    User.logout(loginContinueUrl);
  } else {
    router.push(continuePath);
  }
}

async function reportError(response: Response) {
  const error = (await response.json()) as { error?: string } | undefined;
  const message =
    (response.statusText || response.status) +
    ': ' +
    (error?.error || 'Unknown error');
  captureException(
    new MentiError(message, {
      feature: 'cookie-conversion',
    }),
  );
}
